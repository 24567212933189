import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Subject} from 'rxjs';
import {DialogService, InvoicesService, SnackbarService} from '../../../services';
import {FixInvoiceComponent} from '../../fix-invoice/fix-invoice.component';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'prism-unsynced-invoices-table',
    templateUrl: './unsynced-invoices-table.component.html',
    styleUrls: ['./unsynced-invoices-table.component.scss']
})
export class UnsyncedInvoicesTableComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
    }

    private subDestroyer$: Subject<void>;
    paginator: MatPaginator;
    columnHeaders: string[];
    showLoader: boolean;
    invoiceDataSource: MatTableDataSource<any>;
    paginatorControl: {
        length: number;
        pageSize: number;
        pageSizeOptions: number[]
    };

    @Input()
    set invoicesLength(count) {
        this.paginatorControl = {
            length: count,
            pageSize: this.invoicesService.invoiceTableSize,
            pageSizeOptions: [10, 20, 40, 50, 100]
        };
    }

    @Input()
    set setShowLoader(showLoader: boolean) {
        this.showLoader = showLoader;
    }

    @Input()
    set invoices(invoices) {
        if (!!invoices) {
            this.invoiceDataSource.data = invoices.map((invoice: any) => {
                return {
                    ...invoice,
                    DueDateString: invoice.DueDate,
                    DateString: invoice.Date,
                };
            });
        }
    }

    constructor(private invoicesService: InvoicesService,
                private dialogService: DialogService,
                private snackbarService: SnackbarService) {
        this.subDestroyer$ = new Subject<void>();
        this.invoiceDataSource = new MatTableDataSource<any>([]);
        this.columnHeaders = ['dateCreated', 'address', 'payee', 'amountDue', 'status', 'update', 'delete'];
        this.showLoader = null;
    }

    ngOnInit() {
    }

    openInvoiceUpdateModal(invoiceData) {
        const invoice = invoiceData.invoice;
        const templateSelection = invoiceData.templateSelection;
        const invoiceEmails = invoiceData.invoiceEmails;
        this.dialogService.openDialog(FixInvoiceComponent, {invoice, templateSelection, invoiceEmails});
    }

    cancelInvoice(invoiceUID) {
        this.invoicesService.toggleLoadingQueue(true);
        this.invoicesService.deleteQueuedInvoice(invoiceUID)
            .toPromise()
            .then(() => {
                this.invoicesService.switchDeleteInvoicesLoaderOff();
                this.invoicesService.refreshQueuedInvoicesTableIndex();
                this.invoicesService.getInvoicesQueueCount()
                    .subscribe(
                        () => null,
                        (err) => console.error(err)
                    );
                this.snackbarService.showSnackbar('Invoice has been deleted');
            })
            .catch(err => {
                console.error('Error while deleting invoice:', err);
                this.invoicesService.switchDeleteInvoicesLoaderOff();
                this.snackbarService.handleError('Something went wrong while trying to delete invoice');
                this.invoicesService.toggleLoadingQueue(false);
            });
    }

    paginate(pageEvent: PageEvent) {
        const {pageSize, pageIndex} = pageEvent;
        this.invoicesService.updateQueuedInvoiceListPagination({pageSize, pageIndex});
    }

    ngOnDestroy() {
        let {pageSize} = this.invoicesService.queuedInvoicesPaginationValue;
        this.invoicesService.updateQueuedInvoiceListPagination({pageSize, pageIndex: 0});
        this.subDestroyer$.next();
    }

    getErrorMessages(ValidationErrors: any[]) {
        return ValidationErrors.map((error) => error.message).join(', ');
    }
}
