<div class="xero-doc-container">
    <h1>Xero Integration Documentation</h1>

    <nav class="toc">
        <h2>Table of Contents</h2>
        <ul>
            <li *ngFor="let item of toc">
                <a (click)="scrollToSection(item.id)">{{ item.title }}</a>
            </li>
        </ul>
    </nav>

    <section #xero id="xero">
        <h2>Setup and Configuration Overview</h2>
        <p>This guide will help you connect your BWRM account to your Xero account for streamlined invoicing, payment
            tracking, and reporting.</p>
    </section>

    <section #step1CreateAccount id="step-1-create-account">
        <h2>Step 1: Create a Xero Account</h2>
        <p>To connect BWRM with Xero, you’ll need an active Xero account. If you don’t already have one, follow the
            steps below.</p>
        <ol>
            <li>Visit the <a href="https://www.xero.com/signup" target="_blank">Xero Sign Up</a> page to create a new
                account. You’ll need to enter some personal details and set up login credentials.
            </li>
            <li>Once registered, you may be asked to verify your email address to activate your account.</li>
            <li>Upon logging in, you’ll be guided through setting up your organization profile, where you’ll provide
                details like your organization’s name, type, and contact information.
            </li>
        </ol>

        <div class="screenshot">
            <p>Example Screenshot: Xero Sign Up Page</p>
            <div *ngIf="isLoading; else loadedImage" class="placeholder">
                <img src="../../../assets/images/lazy_asset.png" alt="Placeholder Art" />
            </div>
            <ng-template #loadedImage>
                <img *ngIf="screenshots[7]?.url; else noImage" [src]="screenshots[7].url"
                     alt="Xero Sign Up Page Screenshot" (load)="onImageLoad()" />
            </ng-template>
            <ng-template #noImage>
                <p>No screenshot available.</p>
            </ng-template>
        </div>
    </section>

    <section #step2ChooseOrganisation id="step-2-choose-organization">
        <h2>Step 2: Select or Create Your Organization</h2>
        <p>After creating your account, you’ll need to create or select an organization to connect with BWRM.</p>
        <ol>
            <li>Log into your Xero account, and navigate to the <strong>Organizations</strong> section.</li>
            <li>Here, you can select an existing organization or create a new one if needed.</li>
        </ol>

        <div class="screenshot organisation">
            <p>Example Screenshot: Organization Selection in Xero</p>
            <ng-container *ngIf="screenshots[4]?.url; else loadingPlaceholder">
                <img [src]="screenshots[4].url" alt="Xero Organization Selection" />
            </ng-container>
            <ng-template #loadingPlaceholder>
                <div class="loading-placeholder">
                    <img src="../../../assets/images/lazy_asset.png" alt="Loading...">
                    <p>Loading screenshot...</p>
                </div>
            </ng-template>
        </div>

    </section>

    <section #step3ConnectToXero id="step-3-connect-to-xero">
        <h2>Step 3: Connect BWRM to Xero</h2>
        <p>With your Xero account and organization ready, you can now connect it to your BWRM account:</p>
        <ol>
            <li>Go to the Xero integration section in BWRM and click <strong>Connect to Xero</strong>.</li>
            <li>Log in to Xero if prompted, then follow the on-screen instructions to authorize BWRM to access your Xero
                data.
            </li>
            <li>After authorization, enter any additional required details, such as account codes or payment settings,
                in the BWRM Xero configuration section.
            </li>
        </ol>

        <div class="screenshot">
            <p>Example Screenshot: BWRM Connect to Xero Button</p>
            <ng-container *ngIf="screenshots[6]?.url; else loadingPlaceholder6">
                <img [src]="screenshots[6].url" alt="BWRM Connect to Xero Button" />
            </ng-container>
            <ng-template #loadingPlaceholder6>
                <div class="loading-placeholder">
                    <img src="../../../assets/images/lazy_asset.png" alt="Loading...">
                    <p>Loading screenshot...</p>
                </div>
            </ng-template>
        </div>

        <div class="screenshot">
            <p>Example Screenshot: Login to Xero</p>
            <ng-container *ngIf="screenshots[3]?.url; else loadingPlaceholder3">
                <img [src]="screenshots[3].url" alt="Login to Xero" />
            </ng-container>
            <ng-template #loadingPlaceholder3>
                <div class="loading-placeholder">
                    <img src="../../../assets/images/lazy_asset.png" alt="Loading...">
                    <p>Loading screenshot...</p>
                </div>
            </ng-template>
        </div>

        <div class="screenshot">
            <p>Example Screenshot: Allow Access</p>
            <ng-container *ngIf="screenshots[1]?.url; else loadingPlaceholder1">
                <img [src]="screenshots[1].url" alt="Allow Access" />
            </ng-container>
            <ng-template #loadingPlaceholder1>
                <div class="loading-placeholder">
                    <img src="../../../assets/images/lazy_asset.png" alt="Loading...">
                    <p>Loading screenshot...</p>
                </div>
            </ng-template>
        </div>

    </section>

    <section #step4AddProductsAndServices id="step-4-add-products-and-services">
        <h2>Step 4: Add Products and Services to Xero</h2>
        <p>Before you can invoice your clients, you'll need to add the products and services you offer in Xero. Follow
            these steps to set them up:</p>
        <ol>
            <li>Log in to your Xero account and navigate to the <strong>Business</strong> tab in the main menu.</li>
            <li>Select <strong>Products and Services</strong> from the dropdown menu.</li>
            <li>Click the <strong>Add Item</strong> button to create a new product or service.</li>
            <li>Fill in the required fields:
                <ul>
                    <li><strong>Name</strong> - Enter a descriptive name for the product or service.</li>
                    <li><strong>Code</strong> - (Optional) Enter a unique code to identify the item.</li>
                    <li><strong>Description</strong> - Provide a brief description of the item.</li>
                    <li><strong>Sales Price</strong> - Set the price at which you will sell the item.</li>
                    <li><strong>Account</strong> - Choose the appropriate revenue account from your Chart of Accounts.
                    </li>
                </ul>
            </li>
            <li>Once you have filled out the necessary information, click <strong>Save</strong> to add the product or
                service to your Xero account.
            </li>
        </ol>

        <div class="screenshot">
            <p>Example Screenshot: Adding a Product in Xero</p>
            <ng-container *ngIf="screenshots[5]?.url; else loadingPlaceholder5">
                <img [src]="screenshots[5].url" alt="Adding a Product in Xero" />
            </ng-container>
            <ng-template #loadingPlaceholder5>
                <div class="loading-placeholder">
                    <img src="../../../assets/images/lazy_asset.png" alt="Loading...">
                    <p>Loading screenshot...</p>
                </div>
            </ng-template>
        </div>

    </section>

    <section #step5CreateInvoice id="step-5-create-invoice">
        <h2>Step 5: Create an Invoice and Sync with Xero</h2>
        <p>Once you have connected BWRM to Xero and set up your products and services, you can create an invoice in BWRM that syncs seamlessly with your Xero account. This section covers creating an invoice in BWRM and syncing it with Xero, ensuring a smooth integration for your accounting needs.</p>
        <ol>
            <li>
                <strong>Navigate to the Invoice Creation Section in BWRM:</strong> Open the invoicing feature in your BWRM dashboard to create a new invoice.
            </li>
            <li>
                <strong>Enter Invoice Details:</strong> Fill in necessary fields such as the client’s information, the invoice date, and any relevant descriptions.
                <ul>
                    <li><strong>Account Code:</strong> Ensure that the account code matches an existing Xero account code. The account codes must align to avoid sync issues and ensure accurate ledger categorization.</li>
                    <li><strong>Item Code:</strong> Use item codes that match the ones configured in Xero for products and services. This ensures each line item links correctly to Xero and is categorized appropriately.</li>
                    <li><strong>Additional Fields:</strong> Add payment terms, invoice notes, and any relevant attachments as needed.</li>
                </ul>
            </li>
            <li>
                <strong>Save and Sync the Invoice:</strong> After entering all details, save the invoice. Then click the <strong>Recon</strong> button. This sends the invoice data to your Xero account, where it will appear in your list of Xero invoices.
            </li>
        </ol>

        <h3>Syncing with Xero</h3>
        <p>When you click Recon, BWRM will automatically match account codes, item codes, and invoice fields with Xero's corresponding fields. This ensures your records in BWRM and Xero remain aligned.</p>

        <div class="screenshot">
            <p>Example Screenshot: Syncing an Invoice in BWRM</p>
            <ng-container *ngIf="screenshots[9]?.url; else loadingPlaceholder9">
                <img [src]="screenshots[9].url" alt="Creating and Syncing an Invoice in BWRM" />
            </ng-container>
            <ng-template #loadingPlaceholder9>
                <div class="loading-placeholder">
                    <img src="../../../assets/images/lazy_asset.png" alt="Loading...">
                    <p>Loading screenshot...</p>
                </div>
            </ng-template>
        </div>

    </section>

    <section #invoiceSyncStatus id="invoice-sync-status">
        <h2>Viewing Synced and Unsynced Invoices</h2>
        <p>In this section, you can view all synced and unsynced invoices within BWRM. Use the icons next to each
            invoice to quickly see their sync status:</p>
        <ul>
            <li><strong>Synced Invoices</strong> - These invoices have successfully been synchronized with Xero and will
                display a sync icon.
            </li>
            <li><strong>Unsynced Invoices</strong> - Invoices that failed to sync will display a warning icon.</li>
        </ul>
        <div class="screenshot">
            <p>Example Screenshot: Synced and Unsynced Invoices</p>
            <img *ngIf="screenshots[10]?.url" [src]="screenshots[10].url" alt="Synced and Unsynced Invoices"/>
        </div>
        <div class="screenshot-placeholder">
            <p>Screenshot of synced and unsynced invoices with icons</p>
        </div>

        <h3>Checking Invoice Sync Status</h3>
        <p>Hover over the sync status icon to see details about why an invoice may have failed to sync. Reasons for
            failure could include missing account codes, invalid data, or connection issues.</p>
        <div class="screenshot">
            <p>Example Screenshot: Sync Status Hover Details</p>
            <img *ngIf="screenshots[8]?.url" [src]="screenshots[8].url" alt="Sync Status Hover Details"/>
        </div>
        <div class="screenshot-placeholder">
            <p>Screenshot showing hover details on sync status icon</p>
        </div>

        <h3>Updating Invoice Details</h3>
        <p>If an invoice has failed to sync, you can update its details and attempt to sync it again. Follow these steps
            to update an invoice:</p>
        <ol>
            <li>Click on the invoice you want to update.</li>
            <li>Modify the required fields, such as customer information or account codes, to correct any issues.</li>
            <li>Save your changes and click the sync button to retry the synchronization.</li>
        </ol>
        <div class="screenshot">
            <p>Screenshot of invoice update process</p>
            <img *ngIf="screenshots[13]?.url" [src]="screenshots[13].url" alt="Synced and Unsynced Invoices"/>
        </div>
        <div class="screenshot-placeholder">
        </div>
    </section>

</div>