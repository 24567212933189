import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {Response} from '../../../models';
import {InvoicesService, XeroConnectionService, SnackbarService} from '../../../services';

@Component({
    selector: 'prism-xero-display',
    templateUrl: './xero-display.component.html',
    styleUrls: ['./xero-display.component.scss']
})
export class XeroDisplayComponent implements OnInit, OnDestroy {
    subDestroyer$: Subject<void>;
    loadingQueueCount: boolean;
    reconBusySingle$: Observable<boolean>;
    reconBusyQueue$: Observable<boolean>;
    displayXeroControl: boolean;
    xeroExpiryTimerSubject$: Observable<any>;
    xeroExpiryTimer$: Observable<any>;
    organisation$: Observable<any>;
    invoiceQueueCount$: Observable<number>;
    queueHasError$: Observable<boolean>;
    xeroLoading$: Observable<boolean>;
    loadingQueue$: Observable<boolean>;
    getXeroConnectionDataSub: Subscription;

    constructor(private xeroConnectService: XeroConnectionService,
                private snackbarService: SnackbarService,
                private router: Router,
                private invoicesService: InvoicesService) {

        this.subDestroyer$ = new Subject<void>();
        this.loadingQueueCount = true;
        this.reconBusySingle$ = this.invoicesService.reconBusySingle$;
        this.reconBusyQueue$ = this.invoicesService.reconBusyQueue$;
        this.displayXeroControl = false;
        this.xeroExpiryTimerSubject$ = this.xeroConnectService.getXeroExpiryTimer();
        this.organisation$ = this.xeroConnectService.organisation$;
        this.invoiceQueueCount$ = this.invoicesService.invoicesInQueueCount
            .pipe(
                startWith(-1)
            );
        this.queueHasError$ = this.invoicesService.queueHasErrors$;
        this.xeroLoading$ = this.xeroConnectService.xeroLoading$;
        this.loadingQueue$ = this.invoicesService.loadingQueue$;
    }

    ngOnInit() {
        console.log('XeroDisplayComponent initialized');
        this.xeroExpiryTimerSubject$
            .subscribe(timer => {
                this.xeroExpiryTimer$ = timer;
            });
        this.getXeroConnectionDataSub = this.xeroConnectService.getXeroConnection()
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(
                () => {
                    console.log('Initial Xero connection data fetched');
                },
                () => {
                    this.xeroExpiryTimer$ = timer(0);
                    console.error(`Error getting Xero connection data`);
                }
            );
    }

    connectToXero() {
        this.snackbarService.showSnackbar('Establishing connection with Xero...');
        this.xeroConnectService.getToken()
            .pipe(
                takeUntil(this.subDestroyer$)
            )
            .subscribe(
                () => {
                },
                err => {
                    this.snackbarService.showSnackbar('Failed to connect to Xero.', 2000);
                    console.error('Error getting Xero token:', err);
                }
            );
    }

    ngOnDestroy() {
        this.subDestroyer$.next();
        this.subDestroyer$.complete();
    }

    toggleXeroInfoDisplay() {
        this.displayXeroControl = !this.displayXeroControl;
        if (this.displayXeroControl) {
            this.loadingQueueCount = true;
            this.invoicesService.getInvoicesQueueCount()
                .subscribe(
                    () => {
                        this.loadingQueueCount = false;
                    },
                    err => {
                        console.error('Error getting queued invoice count:', err);
                        this.snackbarService.handleError('Could not get the queued invoices count');
                    }
                );
        }
    }

    navToUnsyncedQueue() {
        this.invoicesService.setSelectedInvoiceTab('unsynced');
        this.router.navigate(['/home/invoices'])
            .then(() => this.displayXeroControl = false)
            .catch(err => console.error('Error navigating to invoices page:', err));
    }

    reconInvoices() {
        const sessionHasExpired = this.xeroConnectService.sessionExpired;
        if (!sessionHasExpired) {
            this.invoicesService.updateSyncedInvoices()
                .pipe(map((updateRes: Response) => updateRes.data))
                .toPromise()
                .then(() => {
                    this.invoicesService.getInvoicesQueueCount()
                        .toPromise()
                        .catch(err => {
                            console.error('Error with invoice recon:', err);
                        });
                    this.snackbarService.showSnackbar('Invoice Sync Complete');
                })
                .catch(err => {
                    console.error('Error updating synced invoices:', err);
                });
        }
    }

    disconnectFromXero() {
        this.snackbarService.showSnackbar('Logging out from Xero...');

        this.xeroConnectService.disconnect()
            .pipe(takeUntil(this.subDestroyer$))
            .subscribe(
                () => {
                    this.snackbarService.showSnackbar('Logged out from Xero.', 2000);
                    this.xeroExpiryTimer$ = timer(0);
                    this.organisation$ = null;
                },
                err => {
                    this.snackbarService.showSnackbar('Failed to log out from Xero.', 2000);
                    console.error('Error logging out from Xero:', err);
                }
            );
    }
}