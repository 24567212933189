<div class="unsynced-invoice-table">
    <table mat-table [dataSource]="invoiceDataSource">
        <section>Invoices</section>
        <ng-container matColumnDef="dateCreated">
            <th mat-header-cell scope="col" *matHeaderCellDef>Date Created</th>
            <td mat-cell *matCellDef="let invoiceData">
                {{ !!invoiceData.invoice.DateString ? invoiceData.invoice.DateString.split('T')[0] : invoiceData.invoice.Date.split('T')[0] }}
            </td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell scope="col" *matHeaderCellDef>Reference</th>
            <td mat-cell *matCellDef="let invoiceData">
                {{ invoiceData.invoice.Reference.split(',')[0] }}
            </td>
        </ng-container>

        <ng-container matColumnDef="payee">
            <th mat-header-cell scope="col" *matHeaderCellDef>Payee</th>
            <td mat-cell *matCellDef="let invoiceData">
                {{ invoiceData.invoice.Contact.Name || invoiceData.invoice.Contact.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="amountDue">
            <th mat-header-cell scope="col" *matHeaderCellDef>Amount Due</th>
            <td mat-cell *matCellDef="let invoiceData">
                {{ invoiceData.invoice.Total }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell scope="col" *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let invoiceData">
                <mat-icon *ngIf="invoiceData.invoice.HasErrors; else notSynced"
                          matTooltip="{{ getErrorMessages(invoiceData.invoice.ValidationErrors) }}">
                    error_outline
                </mat-icon>
                <ng-template #notSynced>
                    <span>Awaiting Sync</span>
                </ng-template>
            </td>

        </ng-container>

        <ng-container matColumnDef="update">
            <th mat-header-cell scope="col" *matHeaderCellDef>Update</th>
            <td mat-cell *matCellDef="let invoiceData">
                <button
                        mat-icon-button
                        (click)="openInvoiceUpdateModal(invoiceData)"
                        [disabled]="(showLoader === true)"
                >
                    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Member" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Member---Job" transform="translate(-1349.000000, -232.000000)" fill="#2F9BBF"
                               fill-rule="nonzero">
                                <g id="Group-31" transform="translate(1349.000000, 177.000000)">
                                    <g id="Group-30" transform="translate(0.000000, 55.000000)">
                                        <g id="0020-pencil5">
                                            <path d="M20.05815,0.9408 C19.46805,0.3507 18.6837,0.02625 17.85,0.02625 C17.0163,0.02625 16.2309,0.3507 15.64185,0.9408 L5.40435,11.1783 C5.34975,11.2329 5.3088,11.298 5.28255,11.37045 L3.18255,17.14545 C3.11325,17.3376 3.1605,17.5518 3.30435,17.69565 C3.4041,17.79645 3.5385,17.85 3.67605,17.85 C3.7359,17.85 3.7968,17.8395 3.8556,17.8185 L9.6306,15.7185 C9.70305,15.69225 9.76815,15.65025 9.82275,15.5967 L20.06025,5.3592 C20.65035,4.7691 20.9748,3.98475 20.9748,3.15 C20.9748,2.31525 20.65035,1.5309 20.06025,0.9408 L20.05815,0.9408 Z M9.16125,14.7714 L4.5528,16.4472 L6.2286,11.83875 L14.7,3.36735 L17.63265,6.3 L9.16125,14.7714 Z M19.31685,4.6158 L18.375,5.5566 L15.44235,2.62395 L16.3842,1.68315 C16.77585,1.2915 17.29665,1.07625 17.85,1.07625 C18.40335,1.07625 18.92415,1.2915 19.31685,1.68315 C19.7085,2.0748 19.92375,2.5956 19.92375,3.14895 C19.92375,3.7023 19.7085,4.2231 19.31685,4.61475 L19.31685,4.6158 Z"
                                                  id="Shape"></path>
                                            <path d="M18.375,21 L1.575,21 C0.70665,21 0,20.29335 0,19.425 L0,2.625 C0,1.75665 0.70665,1.05 1.575,1.05 L12.075,1.05 C12.3648,1.05 12.6,1.2852 12.6,1.575 C12.6,1.8648 12.3648,2.1 12.075,2.1 L1.575,2.1 C1.2852,2.1 1.05,2.3352 1.05,2.625 L1.05,19.425 C1.05,19.7148 1.2852,19.95 1.575,19.95 L18.375,19.95 C18.6648,19.95 18.9,19.7148 18.9,19.425 L18.9,8.925 C18.9,8.6352 19.1352,8.4 19.425,8.4 C19.7148,8.4 19.95,8.6352 19.95,8.925 L19.95,19.425 C19.95,20.29335 19.24335,21 18.375,21 Z"
                                                  id="Shape"></path>
                                        </g>
                                        <g id="0020-pencil5">
                                            <path d="M20.05815,0.9408 C19.46805,0.3507 18.6837,0.02625 17.85,0.02625 C17.0163,0.02625 16.2309,0.3507 15.64185,0.9408 L5.40435,11.1783 C5.34975,11.2329 5.3088,11.298 5.28255,11.37045 L3.18255,17.14545 C3.11325,17.3376 3.1605,17.5518 3.30435,17.69565 C3.4041,17.79645 3.5385,17.85 3.67605,17.85 C3.7359,17.85 3.7968,17.8395 3.8556,17.8185 L9.6306,15.7185 C9.70305,15.69225 9.76815,15.65025 9.82275,15.5967 L20.06025,5.3592 C20.65035,4.7691 20.9748,3.98475 20.9748,3.15 C20.9748,2.31525 20.65035,1.5309 20.06025,0.9408 L20.05815,0.9408 Z M9.16125,14.7714 L4.5528,16.4472 L6.2286,11.83875 L14.7,3.36735 L17.63265,6.3 L9.16125,14.7714 Z M19.31685,4.6158 L18.375,5.5566 L15.44235,2.62395 L16.3842,1.68315 C16.77585,1.2915 17.29665,1.07625 17.85,1.07625 C18.40335,1.07625 18.92415,1.2915 19.31685,1.68315 C19.7085,2.0748 19.92375,2.5956 19.92375,3.14895 C19.92375,3.7023 19.7085,4.2231 19.31685,4.61475 L19.31685,4.6158 Z"
                                                  id="Shape"></path>
                                            <path d="M18.375,21 L1.575,21 C0.70665,21 0,20.29335 0,19.425 L0,2.625 C0,1.75665 0.70665,1.05 1.575,1.05 L12.075,1.05 C12.3648,1.05 12.6,1.2852 12.6,1.575 C12.6,1.8648 12.3648,2.1 12.075,2.1 L1.575,2.1 C1.2852,2.1 1.05,2.3352 1.05,2.625 L1.05,19.425 C1.05,19.7148 1.2852,19.95 1.575,19.95 L18.375,19.95 C18.6648,19.95 18.9,19.7148 18.9,19.425 L18.9,8.925 C18.9,8.6352 19.1352,8.4 19.425,8.4 C19.7148,8.4 19.95,8.6352 19.95,8.925 L19.95,19.425 C19.95,20.29335 19.24335,21 18.375,21 Z"
                                                  id="Shape"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell scope="col" *matHeaderCellDef>Cancel</th>
            <td mat-cell *matCellDef="let invoiceData">
                <button
                        mat-icon-button
                        (click)="cancelInvoice(invoiceData.invoice.InvoiceID)"
                        [disabled]="(showLoader === true)"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnHeaders"></tr>
        <tr mat-row *matRowDef="let invoiceData; columns: columnHeaders">
            #
        </tr>
    </table>
    <mat-progress-bar
            mode="indeterminate"
            *ngIf="(showLoader === true)"
    ></mat-progress-bar>

    <mat-paginator
            [length]="paginatorControl.length"
            [pageSize]="paginatorControl.pageSize"
            [pageSizeOptions]="paginatorControl.pageSizeOptions"
            (page)="paginate($event)"
    >
    </mat-paginator>
</div>