import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../environments';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'prism-xero-documentation',
    templateUrl: './xero-documentation.component.html',
    styleUrls: ['./xero-documentation.component.scss']
})
export class XeroDocumentationComponent implements OnInit {
    screenshots = []
    isLoading : boolean;

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.http.get(environment.apiBaseUrl + '/GetScreenshots')
            .subscribe(
                (response: any) => {
                    this.isLoading = false;
                    if (response && response.data && Array.isArray(response.data.screenshots)) {
                        this.screenshots = response.data.screenshots;
                        if (this.screenshots.length > 7) {
                        } else {
                            console.warn('Not enough screenshots available.');
                        }
                    } else {
                        console.error('Invalid response structure:', response);
                    }
                },
                (error) => {
                    console.error('Error fetching screenshots:', error);
                }
            );
    }

    toc = [
        {id: 'xero', title: 'Xero'},
        {id: 'step-1-create-account', title: 'Step 1: Create Account'},
        {id: 'step-2-choose-organization', title: 'Step 2: Choose Organization'},
        {id: 'step-3-connect-to-xero', title: 'Step 3: Connect to Xero'},
        {id: 'step-4-add-products-and-services', title: 'Step 4: Add Products and Services'},
        {id: 'step-5-create-invoice', title: 'Step 5 Create Invoice'},
        {id: 'invoice-sync-status', title: 'Invoice Sync Status'},
    ];

    @ViewChild('xero') xeroSection!: ElementRef;
    @ViewChild('step1CreateAccount') step1CreateAccount!: ElementRef;
    @ViewChild('step2ChooseOrganisation') step2ChooseOrganizationSection!: ElementRef;
    @ViewChild('step3ConnectToXero') step3ConnectToXeroSection!: ElementRef;
    @ViewChild('invoiceSyncStatus') invoiceSyncStatusSection!: ElementRef;
    @ViewChild('step4AddProductsAndServices') step4AddProductsAndServicesSection!: ElementRef;
    @ViewChild('step5CreateInvoice') step5CreateInvoiceSection!: ElementRef;

    private sectionLookup = {
        'xero': 'xeroSection',
        'step-1-create-account': 'step1CreateAccount',
        'step-2-choose-organization': 'step2ChooseOrganizationSection',
        'step-3-connect-to-xero': 'step3ConnectToXeroSection',
        'invoice-sync-status': 'invoiceSyncStatusSection',
        'step-4-add-products-and-services': 'step4AddProductsAndServicesSection',
        'step-5-create-invoice': 'step5CreateInvoiceSection'
    };

    scrollToSection(sectionId: string): void {
        const section = this[this.sectionLookup[sectionId]];
        if (section) {
            section.nativeElement.scrollIntoView({behavior: 'smooth'});
        }
    }
}
